// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-pages-404-js": () => import("./../../../node_modules/@accrosoft-ltd/accropress-theme-inspiration/src/pages/404.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-pages-404-js" */),
  "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-pages-cms-content-preview-js": () => import("./../../../node_modules/@accrosoft-ltd/accropress-theme-inspiration/src/pages/cms-content-preview.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-pages-cms-content-preview-js" */),
  "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-all-content-js": () => import("./../../../node_modules/@accrosoft-ltd/accropress-theme-inspiration/src/templates/all-content.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-all-content-js" */),
  "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-login-page-template-js": () => import("./../../../node_modules/@accrosoft-ltd/accropress-theme-inspiration/src/templates/LoginPageTemplate.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-login-page-template-js" */),
  "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-search-js": () => import("./../../../node_modules/@accrosoft-ltd/accropress-theme-inspiration/src/templates/search.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-search-js" */),
  "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-standard-page-template-js": () => import("./../../../node_modules/@accrosoft-ltd/accropress-theme-inspiration/src/templates/Standard Page Template.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-accropress-theme-inspiration-src-templates-standard-page-template-js" */),
  "component---src-accrosoft-ltd-accropress-theme-inspiration-templates-homepage-js": () => import("./../../../src/@accrosoft-ltd/accropress-theme-inspiration/templates/Homepage.js" /* webpackChunkName: "component---src-accrosoft-ltd-accropress-theme-inspiration-templates-homepage-js" */)
}

